import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { createSearchParams, useNavigate } from "react-router-dom";
import MainNav from "./MainNav";
import logo from "../../assets/mark_logo.svg";
import { MdSearch, MdMenu } from "react-icons/md";
import { IconContext } from "react-icons";

const Header = () => {
  const [showMenu, setShowSubMenu] = useState(false);

  const navigate = useNavigate();

  const searchHandler = (event) => {
    event.preventDefault();
    navigate({
      pathname: "search",
      search: `?${createSearchParams({
        name: event.target.search.value,
      })}`,
    });
  };

  const showMenuHandler = () => {
    setShowSubMenu(!showMenu);
    if (!showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <React.Fragment>
      <header className="absolute w-full z-50">
        <div className="xl:container  xl:mx-auto px-4 xl:px-4 py-10 flex justify-between">
          <Link to={"/"}>
            <img src={logo} className=" h-20"></img>
          </Link>

          <div className="inline-flex px-3 my-6 text-white">
            <IconContext.Provider value={{ size: "2em" }}>
              <MdMenu
                onClick={showMenuHandler}
                className="hover:cursor-pointer"
              />
            </IconContext.Provider>

            {/* <form onSubmit={searchHandler}>
              <input type="text" name="search" />
              <button type="submit">Search</button>
            </form>
            <SocialIcon
              url="https://twitter.com/Beacon_Academy"
              className="mr-4"
              target="_blank"
              bgColor="#FFF"
              style={{ height: 24, width: 24 }}
            />
            <SocialIcon
              url="https://facebook.com/BeaconAcademyCrowborough"
              className="mr-4"
              target="_blank"
              bgColor="#FFF"
              style={{ height: 24, width: 24 }}
            /> */}
          </div>
        </div>
        <MainNav showMenu={showMenu} closeMenu={showMenuHandler} />
      </header>
    </React.Fragment>
  );
};

export default Header;
