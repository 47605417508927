import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockHandler from "../components/Blocks/BlockHandler";
import image1 from "../assets/home1.jpeg";
import { PortableText } from "@portabletext/react";
import { MyPortableTextComponents } from "../components/common/MyPortableTextComponents.js";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

const Page = () => {
  const [page, setPage] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
      title,
      _id,
      slug,
      mainImage{
        asset->{
          _id,
          url
        }
      },
      imageColors{title},
      body[]{...,asset->{...},
      markDefs[]{
        ...,
        _type == "internalLink" => {
          "slug": @.reference->slug
        }
      }},
      content,
    }`
      )
      .then((data) => setPage(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!page)
    return (
      <div className=" absolute w-full h-full bg-white block z-50 ">
        Loading...
      </div>
    );

  return (
    <main>
      <div
        className={`w-full h-screen/3 lg:h-screen/2 absolute z-10 bg-${page.imageColors.title} mix-blend-multiply`}
      ></div>
      <img
        src={page.mainImage ? urlFor(page.mainImage).url() : image1}
        alt="Header"
        className={`object-top object-cover w-full h-screen/3 lg:h-screen/2 ${
          page.imageColors.title !== "transparent" ? "grayscale" : ""
        }`}
      />
      <article className="container mx-auto text-left">
        <div className="px-16 lg:px-48 py-12 lg:py-5 prose lg:prose-xl max-w-full">
          <h1 className="cursive text-3xl lg:text-4xl mb-6">{page.title}</h1>
          <PortableText
            value={page.body}
            components={MyPortableTextComponents}
          />
          {page.content && <BlockHandler items={page.content} />}
        </div>
      </article>
    </main>
  );
};

export default Page;
