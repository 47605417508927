import { MdOutlineDescription } from "react-icons/md";
import { IconContext } from "react-icons";

const Asset = (props) => {
  return (
    <div>
      <a
        href={`${props.asset.url}`}
        target="_blank"
        rel="noreferrer"
        className="border p-2 mb-2 text-mark-teal block hover:bg-mark-teal hover:text-white hover:border-mark-teal"
      >
        <IconContext.Provider value={{ size: "1.25em" }}>
          <MdOutlineDescription className="inline mr-1" />
        </IconContext.Provider>

        {props.title || props.asset.originalFilename}
      </a>
    </div>
  );
};

export default Asset;
