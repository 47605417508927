import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import sanityClient from "../client";
import image1 from "../assets/home1.jpeg";

const Search = () => {
  const [searchResults, setSearchResults] = useState(null);

  const [searchParams] = useSearchParams({});

  const searchTerms = searchParams.get("name").split(" ");

  console.log(searchTerms);

  const name = searchParams.get("name");
  const name2 = searchParams.get("name2");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "page"]
        | score(content[].content[].body[].children[].text match "${name}" || content[].content[].body[].children[].text match "${name2}")
        | score(content[].body[].children[].text match "${name}" || content[].body[].children[].text match "${name2}",
          boost(title match "${name}", 3))
          | order(_score desc)
          [_score > 0]{_score, title, content, slug, _id}`
      )
      .then((data) => setSearchResults(data))
      .catch(console.error);
  }, [name, name2]);

  if (!searchResults) return <div>Loooooading...</div>;

  return (
    <main>
      <img
        src={image1}
        alt="Mark Education Trust"
        className="object-top object-cover w-full h-screen/2"
      />
      <article className="container mx-auto text-left">
        <div className="px-16 lg:px-48 py-12 lg:py-5 prose lg:prose-xl max-w-full">
          <h1 className="text-3xl lg:text-4xl mb-4">Search Results</h1>
          {searchResults &&
            searchResults.map((item) => (
              <Link to={"/" + item.slug.current} key={item.slug.current}>
                <h2 className="text-2xl">{item.title}</h2>
                <p>http://www.markeducationtrust.org/{item.slug.current}</p>
              </Link>
            ))}
        </div>
      </article>
    </main>
  );
};

export default Search;
