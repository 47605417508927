const YouTube = (props) => {
  return (
    <iframe
      width="560"
      height="315"
      title={props.id}
      src={"https://www.youtube.com/embed/" + props.id}
    />
  );
};

export default YouTube;
