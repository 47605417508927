import React, { useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import { MdExpandMore } from "react-icons/md";
import { IconContext } from "react-icons";
import { PortableText } from "@portabletext/react";
import { MyPortableTextComponents } from "../common/MyPortableTextComponents";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

const AccordionItem = (props) => {
  const [active, setActive] = useState(false);
  const accordionHandler = () => {
    setActive(!active);
  };

  return (
    <React.Fragment>
      <div
        className={`cursor-pointer border-solid border-gray-300 border p-4 flex flex-row ${
          props.index > 0 ? "border-t-0" : ""
        }`}
        onClick={accordionHandler}
      >
        <div className="grow">
          <h1 className="text-xl font-bold">{props.contents.title}</h1>
          <h2>{props.contents.subtitle}</h2>
        </div>
        <div
          className={`text-right self-center transition-all ${
            active ? "rotate-180" : ""
          }`}
        >
          <IconContext.Provider value={{ size: "1.5em" }}>
            <MdExpandMore />
          </IconContext.Provider>
        </div>
      </div>
      <div
        className={`border-solid border-gray-300 border p-4 border-t-0 flex flex-row transition-all overflow-hidden ${
          !active ? "max-h-0 py-0 border-b-0" : "max-h-none"
        }`}
      >
        {props.contents.mainImage && (
          <div className=" basis-1/4 mr-4">
            <img
              src={urlFor(props.contents.mainImage).url()}
              alt={props.contents.title}
              className=""
            />
          </div>
        )}
        <div className="basis-3/4 grow">
          <PortableText
            value={props.contents.body}
            components={MyPortableTextComponents}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccordionItem;
