import YouTube from "../Blocks/YouTube";
import Accordion from "../Blocks/Accordion";
import DataTable from "../Blocks/DataTable";
import Asset from "../Blocks/Asset";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

export const MyPortableTextComponents = {
  types: {
    youtube: ({ value }) => <YouTube id={value.id} />,
    accordion: ({ value }) => (
      <Accordion title={value.title} contents={value.content} />
    ),
    asset: ({ value }) => <Asset asset={value.asset} title={value.title} />,
    table: ({ value }) => <DataTable contents={value} />,
    image: ({ value }) => (
      <img
        src={urlFor(value).url()}
        alt={value.altText || ""}
        className="mb-4"
      />
    ),
  },
  list: {
    bullet: ({ children }) => <ul className="list-disc pl-4">{children}</ul>,
    checklist: ({ children }) => (
      <ul className="check-list pl-6">{children}</ul>
    ),
    number: ({ children }) => <ol className="list-decimal pl-4">{children}</ol>,
  },
  block: {
    h1: ({ children }) => (
      <h1 className="cursive text-3xl lg:text-4xl mb-4 text-mark-dark-grey">
        {children}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="cursive text-2xl lg:text-3xl mt-10 mb-8 text-mark-dark-grey">
        {children}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="cursive text-xl lg:text-2xl mt-8 mb-3 text-mark-dark-grey">
        {children}
      </h3>
    ),
    h4: ({ children }) => (
      <h3 className="cursive text-xl lg:text-xl mt-8 mb-3">{children}</h3>
    ),
  },
  marks: {
    link: ({ value, children }) => (
      <a href={value.href} className="text-mark-teal hover:underline">
        {children}
      </a>
    ),
    internalLink: ({ value, children }) => {
      const { slug = {} } = value;
      const href = `/${slug.current}`;
      return (
        <a href={href} className="text-mark-teal hover:underline">
          {children}
        </a>
      );
    },
  },
};

export default MyPortableTextComponents;
