import BlockContent from "@sanity/block-content-to-react";
import Accordion from "./Accordion";
import YouTube from "./YouTube";

const BlockHandler = (props) => {
  return props.items.map((item) => {
    if (item._type === "accordion") {
      return <Accordion contents={item.content} />;
    }

    if (item._type === "textBlock") {
      return (
        <BlockContent
          blocks={item.body}
          projectId="p8fne4nk"
          dataset="production"
        />
      );
    }

    if (item._type === "youtube") {
      return <YouTube id={item.id} />;
    }

    return <div>Nothing here</div>;
  });
};

export default BlockHandler;
