const DataTable = (props) => {
  console.log(props);
  return (
    <table>
      <tr>
        {props.contents.rows[0].cells.map((item, index) => (
          <th>{item}</th>
        ))}
      </tr>
      {props.contents.rows.slice(1).map((row, index) => (
        <tr>
          {row.cells.map((cell, index) => (
            <td>{cell}</td>
          ))}
        </tr>
      ))}
    </table>
  );
};

export default DataTable;
