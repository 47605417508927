import React from "react";
import logo from "../../assets/mark_logo.svg";
import beaconLogo from "../../assets/beacon_logo.svg";
import uplandsLogo from "../../assets/uplands_logo.svg";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="bg-[#343638] text-white p-4 ">
        <div className="xl:container  xl:mx-auto px-4 xl:px-4 py-10  justify-between">
          <div className="grid grid-cols-1 md:grid-cols-6">
            <div className="md:pb-0 pb-5 inline">
              <img src={logo} className=" h-16 opacity-50"></img>
            </div>
            <div className="md:pb-0 pb-5">
              <img src={beaconLogo} className="h-16 opacity-50"></img>
            </div>
            <div className="md:pb-0 pb-5">
              <img src={uplandsLogo} className="h-16 opacity-50"></img>
            </div>
            <div className="md:col-span-2"></div>
            <div className="text-left">
              <p>FOLLOW US</p>
              <SocialIcon
                url="https://www.facebook.com/MARKEducationTrust"
                className="-left-4"
                target="_blank"
                fgColor="#FFF"
                bgColor="transparent"
                style={{ height: 50, width: 50 }}
              />
              <SocialIcon
                url="https://www.instagram.com/markeducationtrust/"
                className="-left-4"
                target="_blank"
                fgColor="#FFF"
                bgColor="transparent"
                style={{ height: 50, width: 50 }}
              />
              <SocialIcon
                url="https://twitter.com/MARK_EdTrust"
                className="-left-4"
                target="_blank"
                fgColor="#FFF"
                bgColor="transparent"
                style={{ height: 50, width: 50 }}
              />
            </div>
          </div>
          <p className="opacity-80 text-sm text-right pt-5">
            Copyright © 2022 MARK Education Trust
          </p>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
