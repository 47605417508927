import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
  return (
    <div className="mb-6 block bg-white drop-shadow-lg">
      {props.contents &&
        props.contents.map((item, index) => (
          <AccordionItem
            key={index}
            index={index}
            contents={item}
          ></AccordionItem>
        ))}
    </div>
  );
};

export default Accordion;
