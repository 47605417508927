import "./App.css";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./Pages/Home";
import Page from "./Pages/Page";
import Search from "./Pages/Search";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Page />} path=":slug" />
          <Route element={<Page />} path="/news/:slug" />
          <Route element={<Search />} path="/search" />
        </Routes>
        <Footer />
      </BrowserRouter>
      {/* <div className="bg-mark-teal bg-mark-blue bg-mark-yellow bg-mark-light-teal bg-mark-light-blue"></div> */}
    </div>
  );
}

export default App;
