import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MainNavMenu from "./MainNavMenu";
import sanityClient from "../../client";
import { IconContext } from "react-icons";
import { MdClose } from "react-icons/md";

const MainNav = (props) => {
  const [menu, setMenu] = useState(null);

  const [subMenu, setSubMenu] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'navigation'] {
          name,
          links[]{
            itemType,targetMenu->{name, title},title,target->{slug, title},
          }
        }`
      )
      .then((data) => setMenu(data))
      .catch(console.error);
  }, []);

  const menuHandler = (menuName) => {
    const menuIndex = menu.findIndex((item) => item.name === menuName);
    setSubMenu(menu[menuIndex]);
  };

  return (
    <div>
      <nav
        className={`bg-white fixed px-10 py-20  text-xl top-0 h-screen max-w-full w-[32rem] z-50 transition-all duration-500 ease-in-out ${
          props.showMenu ? "right-0" : "right-[-32rem]"
        }`}
      >
        <IconContext.Provider value={{ size: "2em" }}>
          <MdClose
            onClick={props.closeMenu}
            className="absolute top-10 right-10 hover:cursor-pointer"
          />
        </IconContext.Provider>

        <ul className="container mx-auto space-y-8 justify-between p-4 pt-0 text-black text-left relative">
          <li>
            <NavLink
              to={"/welcome"}
              onClick={props.closeMenu}
              className="hover:cursor-pointer hover:text-red-400"
            >
              Welcome
            </NavLink>
          </li>
          <li
            onClick={() => menuHandler("about-our-trust")}
            className={`hover:cursor-pointer ${
              subMenu && subMenu.name === "about-our-trust"
                ? "before:content-['-']"
                : "before:content-['+']"
            } before:absolute before:-left-2 `}
          >
            About our Trust
            {subMenu && subMenu.name === "about-our-trust" && (
              <MainNavMenu menu={subMenu} closeMainMenu={props.closeMenu} />
            )}
          </li>
          <li
            onClick={() => menuHandler("governance")}
            className={`hover:cursor-pointer ${
              subMenu && subMenu.name === "governance"
                ? "before:content-['-']"
                : "before:content-['+']"
            } before:absolute before:-left-2 `}
          >
            Trust Governance & Finance
            {subMenu && subMenu.name === "governance" && (
              <MainNavMenu menu={subMenu} closeMainMenu={props.closeMenu} />
            )}
          </li>
          <li>
            <NavLink
              to={"/teacher-training"}
              onClick={props.closeMenu}
              className="hover:cursor-pointer hover:text-red-400"
            >
              Teacher Training & Development
            </NavLink>
          </li>
          <li
            onClick={() => menuHandler("key-documents")}
            className={`hover:cursor-pointer ${
              subMenu && subMenu.name === "key-documents"
                ? "before:content-['-']"
                : "before:content-['+']"
            } before:absolute before:-left-2 `}
          >
            Key Documents & Information
            {subMenu && subMenu.name === "key-documents" && (
              <MainNavMenu menu={subMenu} closeMainMenu={props.closeMenu} />
            )}
          </li>

          <li
            onClick={() => menuHandler("join-us")}
            className={`hover:cursor-pointer ${
              subMenu && subMenu.name === "join-us"
                ? "before:content-['-']"
                : "before:content-['+']"
            } before:absolute before:-left-2 `}
          >
            Join Us
            {subMenu && subMenu.name === "join-us" && (
              <MainNavMenu menu={subMenu} closeMainMenu={props.closeMenu} />
            )}
          </li>
          <li>
            <NavLink
              to={"/contact-us"}
              onClick={props.closeMenu}
              className="hover:cursor-pointer hover:text-red-400"
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
      {props.showMenu && (
        <div
          className="fixed top-0 left-0 w-full h-screen z-49 bg-black/[.5]"
          onClick={props.closeMenu}
        ></div>
      )}
    </div>
  );
};

export default MainNav;
