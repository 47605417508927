import React from "react";
import { Link } from "react-router-dom";

const MainNavMenu = (props) => {
  return (
    <React.Fragment>
      <ul className="mx-auto pt-2 text-black text-base z-50 relative">
        {props.menu.links &&
          props.menu.links.map((item) => (
            <li>
              <Link
                onClick={props.closeMainMenu}
                to={"/" + item.target.slug.current}
                key={item.target.slug.current}
                className="hover:text-red-400"
              >
                {item.title || item.target.title}
              </Link>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );
};

export default MainNavMenu;
