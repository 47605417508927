import React, { useState, useEffect } from "react";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import LatestNews from "../components/Layout/LatestNews";
import beaconLogo from "../assets/beacon_logo.svg";
import uplandsLogo from "../assets/uplands_logo.svg";
import bgBeacon from "../assets/beacon_bg.png";
import bgUplands from "../assets/uplands_bg.png";

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => {
  return builder.image(source);
};

const Home = () => {
  const [homePage, setHomePage] = useState();
  const [news, setNews] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "homePage"]{

          mainImage{
            asset->{
              _id,
              url
            }
          },
    imageColors{title},
        }`
      )
      .then((data) => setHomePage(data[0]))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "news"]|order(_createdAt desc){
              }`
      )
      .then((data) => setNews(data))
      .catch(console.error);
  }, []);

  if (!homePage)
    return (
      <div className=" absolute w-full h-full bg-white block z-50 ">
        Loading...
      </div>
    );

  return (
    <React.Fragment>
      <main className="bg-mark-dark-grey/10 pb-8">
        <div
          className={`w-full h-screen absolute z-10 bg-${homePage.imageColors.title} mix-blend-multiply`}
        ></div>
        <div className="absolute bottom-24 left-4 md:left-24 pr-4 md:pr-24 z-20 flex flex-wrap-reverse">
          <h1 className="text-3xl lg:text-5xl md:pr-8 text-white text-left">
            <strong>MARK</strong> Education Trust <br /> Ambitious for
            excellence
          </h1>
        </div>

        <img
          src={urlFor(homePage.mainImage).url()}
          alt="MARK Education Trust"
          className={`object-top object-cover w-full h-screen ${
            homePage.imageColors.title !== "transparent" ? "grayscale" : ""
          }`}
        />
        <div className="bg-mark-dark-grey -mt-8 relative z-30 m-8 grid grid-cols-1 md:grid-cols-2 gap-20 p-3 md:p-20">
          <div className="text-white text-left">
            <h2 className="tracking-widest mb-8 text-lg uppercase">
              What we do
            </h2>
            <h1 className="text-3xl lg:text-5xl">
              Our mission at MARK Education Trust is to provide the best
              possible education for our students, preparing them for life, so
              they can stand equally alongside their peers, locally, nationally
              and globally.
            </h1>
          </div>
          <div className="text-right">
            <p className="text-left text-white">
              The guiding <strong>values</strong> of MARK Education Trust are:
              <br />
              M – Motivated: to create exceptional, caring and safe educational
              establishments.
              <br />A – Ambitious: high expectations of staff and students.
              <br />R – Resourceful: ensuring efficiency and value for money.
              <br />K – Knowledgeable: valuing learning for life.
            </p>
            <p className="text-left text-white">
              Our <strong>vision</strong> is that MARK Education Trust will
              create:
            </p>
            <ul className="text-left text-white check-list pl-6">
              <li>
                Schools with their own strong identity underpinned by shared
                values.
              </li>
              <li>
                Schools with expertly taught broad and balanced curriculums
                which give students the knowledge they need to further their
                education and thrive in adult life.
              </li>
              <li>
                Schools which through collaboration constantly improve so that
                they are recognised for their outcomes at a local, regional and
                national level.
              </li>
              <li>
                Schools which are the school of choice for their community and
                the employer of choice for the best professionals.
              </li>
              <li>
                Safe and happy environments which enable students and staff to
                thrive and succeed.
              </li>
              <li>
                Governance, leadership and management which is robust at all
                levels, ensuring that the trust is fully accountable to its
                stakeholders.
              </li>
              <li>
                A strategy of ‘growth with care’, ensuring that the trust is
                able to meet the needs of and invest in its own academies as
                well as the capacity to support further academies.
              </li>
            </ul>
          </div>
        </div>
        <div className="text-left p-3 md:px-28 mx-auto">
          <h2 className="tracking-wider mt-4 text-lg uppercase">Discover</h2>
          <h1 className="text-3xl mt-4 lg:text-5xl">Our schools</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-4">
            <a
              href="http://www.beacon-academy.org/web"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="bg-mark-dark-grey bg-cover mb-4 md:mr-2 md:mb-0 pt-[8%] aspect-[559/224]"
                style={{
                  backgroundImage: `url(${bgBeacon})`,
                }}
              >
                <img
                  src={beaconLogo}
                  className="block m-auto w-1/2 "
                  alt="Beacon Acadmey"
                ></img>
              </div>
            </a>
            <a
              href="https://www.uplands-academy.org/"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className="bg-mark-dark-grey bg-cover pt-[8%] md:ml-2 aspect-[559/224]"
                style={{
                  backgroundImage: `url(${bgUplands})`,
                }}
              >
                <img
                  src={uplandsLogo}
                  className="block m-auto w-1/2"
                  alt="Uplands Academy"
                ></img>
              </div>
            </a>
          </div>
        </div>
      </main>
      {/* {news && (
        <div className="text-left  p-3 md:px-28 mx-auto pt-4">
          <h1 className="text-3xl lg:text-5xl mt-4">Latest news</h1>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4">
            <LatestNews className="lg:col-span-2" item="0" />
            <LatestNews item="1" />
            <LatestNews item="2" />
          </div>
        </div>
      )} */}
    </React.Fragment>
  );
};

export default Home;
